<template>
  <!-- 培训记录 -->
  <div class="trainingRecord">
    <van-nav-bar :title="$t('trainRecords.TrTxt1')" left-text="" left-arrow @click-left="onClickLeft"
      @click-right="onClickRight" :fixed="true" />
    <div class="fixedH" style="height: 1.22667rem;"></div>
    <van-search v-model="value" shape="round" :placeholder="$t('trainRecords.TrTxt2')" @search="onSearch" />
    <div class="overflow_div">
      <van-list v-model="loadingAlready" :finished="finishedAlready" :finished-text="$t('trainRecords.TrTxt3')"
        @load="onLoadAlready">
        <div class="cellGroup already_border" v-for="(item, index) in alreadyList" :key="index"
          @click="toDetails(item, index)">
          <van-cell class="first_cell" :title="item.coursename" :label="$t('trainRecords.TrTxt4') + item.teacher_empname">
            <template #right-icon>
              <img v-if="item.isfinish" src="@/assets/img/trained.png" alt="" />
            </template>
          </van-cell>
          <div class="middle_cell">
            <div>{{ item.trainstyle }}</div>
            <van-divider />
            <div style="color:red;">{{ item.signres }}</div>
          </div>
          <van-cell class="last_cell" :title="$t('trainRecords.TrTxt5') + item.planbt">
            <template #default v-if="!item.signres">
              <van-button type="warning" size="mini" @click.stop="leave(item)">我要请假</van-button>
            </template>
          </van-cell>

        </div>
      </van-list>
    </div>
    <!-- 我要请假 -->
    <van-dialog v-model="showDisagree" :title="'我要请假'" show-cancel-button confirmButtonColor="#2B8DF0"
      :beforeClose="confirm">
      <van-field required label="请假原因" readonly />
      <van-field v-model="disagreeReason" rows="8" class="disagreeReason" autosize type="textarea"
        :placeholder="$t('jxAppraiserReview.revTxt5')" show-word-limit />
    </van-dialog>
  </div>
</template>

<script>
import { Toast } from "vant";
import { searchFunc } from '@/utils';
const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {};
import { getHisTrainRecordList, saveTrainLeave } from '@api/wxpx.js'
export default {
  name: "trainRecords",
  data () {
    return {
      userInfo,
      value: "",
      active: 0,
      isLoadingAwait: false,
      awaitList: [],
      loadingAwait: false,
      finishedAwait: false,
      isLoadingAlready: false,
      alreadyList: [],
      loadingAlready: false,
      finishedAlready: false,
      oldlist: [],
      form: {
        coursename: "",
        teacher_empname: ""
      },
      showDisagree: false,
      disagreeReason: '',
      qingjiaAutoid: ''
    };
  },
  methods: {
    //  弹窗关闭
    confirm (action, done) {
      if (action == 'confirm')      {
        if (this.disagreeReason)
        {
          saveTrainLeave({
            autoid: this.qingjiaAutoid,
            username: this.userInfo.username,
            leavereason: this.disagreeReason
          }).then(res => {
            if (res.data[0].info)
            {
              Toast.fail(res.data[0].info);
            } else            {
              Toast({
                message: this.$t('module.submitScu'),
                position: 'top'
              })
            }
            this.onLoadAlready()
            done()
          })
        } else
        {
          Toast({
            message: this.$t('jxAppraiserReview.revTxt12'),
            position: 'top'
          })
          done(false)
        }
      } else      {
        done()
      }
    },
    // 我要请假
    leave (item) {
      this.showDisagree = true
      this.qingjiaAutoid = item.autoid
    },
    // 回退
    onClickLeft () {
      if (window.CloundEntid == '115')      {
        window.location.href = 'http://hyclound.cn/hgvis_115_h5/pc2022_5.html'
      } else      {
        this.$router.push('/home')
      }
    },
    onClickRight () { },
    onLoadAlready () {
      getHisTrainRecordList({
        empid: this.userInfo.empid
      }).then(res => {
        // console.log(res)
        this.alreadyList = res.data;
        this.oldlist = this.alreadyList;
        // 加载状态结束
        this.loadingAlready = false;
        this.finishedAlready = true;
      })
    },
    toDetails (itm) {
      this.$router.push({
        path: "/trainRecordsDetails",
        query: { autoid: itm.autoid },
      });
    },
    // 搜索
    onSearch () {
      var arr = searchFunc(this.value, this.form, this.oldlist);
      if (arr.length > 0)
      {
        this.alreadyList = arr;
        this.value = "";
      } else
      {
        Toast(this.$t('trainRecords.TrTxt6'));
      }
    },
  },
};
</script>

<style lang="less" scoped>
.van-dialog {
  .van-dialog__header {
    font-size: 36px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    line-height: 62px;
    color: #333333;
  }

  /deep/.van-dialog__content {
    .disagreeReason {
      .van-field__value {
        border: 2px solid #cccccc;
        opacity: 1;
        border-radius: 8px;
      }
    }
  }
}

.trainingRecord {
  height: 100vh;
  background: #eceaea;

  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }

    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }

    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }

  .overflow_div {
    overflow: auto;
    height: calc(100% - 210px);

    // background: #fff;
    ::v-deep.van-tab {
      font-size: 32px;
      font-family: Source Han Sans CN;
      font-weight: 500;
    }

    .await_border {
      border-left: 8px solid #ff8b00;
    }

    .already_border {
      border-left: 8px solid #2b8df0;
    }

    .cellGroup {
      margin: 20px 30px;
      border-radius: 8px;

      .first_cell {
        border-radius: 0 8px 0 0;
        padding: 0;

        // padding-bottom: 20px;
        .van-cell__title {
          padding: 20px 30px;
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: 500;

          .van-cell__label {
            font-size: 28px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 48px;
            color: #666666;
          }
        }

        img {
          width: 126px;
          height: 126px;
        }
      }

      .first_cell::after {
        border-bottom: unset;
      }

      .middle_cell {
        padding: 0 30px;
        background: #ffffff;
        display: flex;
        align-items: center;
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 48px;
        color: #999999;
        justify-content: space-between;
        .van-divider {
          background: #ffffff;
          margin: 0;
          width: 60%;
        }
      }

      .last_cell::after {
        border-bottom: unset;
      }

      .last_cell {
        border-radius: 0 0 8px 0;

        .van-cell__title {
          flex: 2;
          font-size: 28px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          line-height: 48px;
          color: #666666;
        }

        .van-cell__value {
          flex: 1;
          font-size: 28px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 48px;
          color: #999999;

          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }
    }
  }
}
</style>